body {
  background-image: linear-gradient(to right, #FF9D9D, #FFD2D2, #FF9D9D );
  font-family: 'Roboto', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #291749;
}

h1, h2, h3, h4 {
  font-family: 'Pacifico', cursive, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #291749;
}

a {
  text-decoration: none; 
  color: #291749;
}

p {
  color: #291749;
}

.navbar {
  background: #FF9D9D;
}

.navbar-brand {
  font-family: 'Pacifico', cursive, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: calc(1.325rem + .9vw);
  color: #291749;
}

a:hover {
  color: #291749;
  text-decoration: underline; 
}

.carousel-caption {
  position: static;
}

@media (min-width: 768px) {
  .carousel-caption {
    position: absolute;
  }
}

.opacity {
  background: rgba(255,255,255,0.75);
}

.card {
  margin-bottom: 5px;
}

.card, .card-img, .card-img-top {
  border: 0;
}

.icons {
  margin-left: 10px;
}

.extra-margin {
  margin-bottom: 30px;
}

button {
  background-image: linear-gradient(to right, #291749, #FFD2D2 );
  margin-right: 10px;
  border-radius: 0.375rem;
  color: #FFFFFF;
  border: 0px;
  box-shadow: 0px 8px 15px rgba(0,0,0,0.1);
}

button:hover {
  background-color: #291749;
  box-shadow: 0px 15px 20px rgba(41, 23, 73, 0.4);
  color: #fff;
  transform: translateY(-7px);
}